/*--------------------------------------------------------------------------*
 * ハンバーガーメニュー
 *--------------------------------------------------------------------------*/
$(function(){
    $('[data-nav-trigger]').on('click', function(){
        $(this).toggleClass('active');
        $('[data-nav]').toggleClass('visible');

        var label = $('#menuLabel').text();
        if (label === 'MENU') {
            $('#menuLabel').text('CLOSE');
        } else {
            $('#menuLabel').text('MENU');
        }
    });

    if ($(window).width() <= 1024) {
        $('[data-submenu-trigger]').on('click', function(){
            $(this).toggleClass('active');
            var target = $(this).attr('data-submenu-trigger');
            $('[data-submenu="' + target + '"]').toggleClass('visible');
            return false;
        });
        $('[data-fmenu-trigger]').on('click', function(){
            $(this).toggleClass('active');
            var target = $(this).attr('data-fmenu-trigger');
            $('[data-fmenu="' + target + '"]').toggleClass('visible');
            return false;
        });

    }
});
